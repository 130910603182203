var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LessonEntityDetails__Component" },
    [
      _c(
        "b-switch",
        {
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.status,
            callback: function($$v) {
              _vm.$set(_vm.model, "status", $$v)
            },
            expression: "model.status"
          }
        },
        [_vm._v(_vm._s(_vm.model.status ? "פעיל" : "לא פעיל"))]
      ),
      _c("FieldInlineText", {
        attrs: { label: "שם יחידת הלימוד" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.name,
          callback: function($$v) {
            _vm.$set(_vm.model, "name", $$v)
          },
          expression: "model.name"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "סוג",
          filterable: true,
          clearable: false,
          optionsAsync: _vm.getLessonTypes
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.lessonType,
          callback: function($$v) {
            _vm.$set(_vm.model, "lessonType", $$v)
          },
          expression: "model.lessonType"
        }
      }),
      _c("FieldInlineText", {
        attrs: { type: "number", label: "סדר" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.sortOrder,
          callback: function($$v) {
            _vm.$set(_vm.model, "sortOrder", $$v)
          },
          expression: "model.sortOrder"
        }
      }),
      _c(
        "b-field",
        { attrs: { label: "פתיח" } },
        [
          _c("NewActivityEditor", {
            ref: "editor",
            attrs: {
              ckEditorContent: _vm.model.supportingMaterials,
              content: _vm.model.supportingMaterials
            },
            on: { onEditorChange: _vm.onEditorChange }
          })
        ],
        1
      ),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.model.btnColor,
            expression: "model.btnColor"
          }
        ],
        attrs: { type: "color" },
        domProps: { value: _vm.model.btnColor },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.model, "btnColor", $event.target.value)
            },
            _vm.onChange
          ]
        }
      }),
      _c(
        "div",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c(
            "b-switch",
            {
              on: { input: _vm.onChange },
              model: {
                value: _vm.model.isHidden,
                callback: function($$v) {
                  _vm.$set(_vm.model, "isHidden", $$v)
                },
                expression: "model.isHidden"
              }
            },
            [_vm._v(_vm._s(_vm.model.isHidden ? "מוסתר" : "לא מוסתר"))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }